/*-----------------------------------------------------------------------------------*/
/*	Navigation
/*-----------------------------------------------------------------------------------*/
.navbar {
 background: #fff;
 color: #000;
}

.navbar.navbar-home {
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 15;
}

.navbar-default .navbar-nav > li.divider {
  border-right: 1px solid #415786;
  padding: 10px 0 3px;
  margin: 5px 15px 0;
}

.navbar-default { border-bottom: none; }

.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav > li > a:focus {
  color: #bec5d5;
  font-weight: 300;
}

.navbar-default .navbar-nav > li > a:hover {
  background: none;
  color: #fff;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:visited {
  background: none;
  color: #fff;
}

.navbar-default .navbar-toggle {
  border-color: #ff3e97;
  border-width: 2px;
  color: #fff;
  margin: 0 15px 0;
  padding: 5px 10px;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background: none;
}

.navbar .container {
  padding-bottom: 0;
  padding-top: 10px;
}

.navbar .dropdown-toggle,
.navbar-search .dropdown-toggle {
  font-size: 16px;
  text-align: left;
  text-transform: none;
}

.navbar .dropdown-label,
.navbar-search .dropdown-label{
  float: left;
  width: 96%;
}

.navbar .dropdown .dropdown-menu,
.navbar-search .dropdown .dropdown-menu{
  width: 100%;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form { border: none; }

.navbar-default .navbar-collapse .separator {
  border-bottom: 1px solid #415786;
  font-size: 10px;
  margin-bottom: 20px;
}

.nav > li > a:hover, .nav > li > a:focus {
    background: transparent !important;
    color: #ff3e97 !important;
}

.navbar-nav > li > .dropdown-menu {
  /*background: #1e2c52;*/
  color: #fff;
  margin-top: 10px;
}

.nav-user > .dropdown-menu {
  min-width: 180px;
  padding-bottom: 10px;
  padding-top: 10px;
  -webkit-box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px 0px rgba(0, 0, 0, 0.05);;
}

.nav-user > .dropdown-menu > li > a {
  height: 48px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.nav-user > .dropdown-menu > li > a:hover, .nav-user > .dropdown-menu > li > a:focus {
  color: #262626 !important;
  text-decoration: none !important;
  background-color: #f5f5f5 !important;
}

.nav-user .dropdown-menu > li > a:focus,
.nav-user .dropdown-menu > li > a:hover {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  color: #fff;
}

ul.dropdown-menu.jsr-expanded-menu {
  width: 259px !important;
  padding: 0px;
}

/*
 * Country Dropdown
 */
.navbar .country-dropdown {
  float: left;
  margin: 6px 0 0 5px;
}

.navbar .country-dropdown .btn-country-dropdown {
  background: transparent;
  color: #1b284c;
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 2px 10px;
  text-transform: none;
}
.navbar-default .country-dropdown .btn-country-dropdown { color: #fff; }

.navbar .btn-country-dropdown:hover,
.navbar .btn-country-dropdown:focus,
.navbar .btn-country-dropdown:active,
.navbar .btn-country-dropdown.active { background: transparent; }

.navbar .country-dropdown .country-menu {
  background: rgba(0, 0, 0, 0.25) none repeat scroll 0 0;
  border: none;
  color: #fff;
  padding: 0;
  -webkit-box-shadow: 0px 15px 25px 1px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 15px 25px 1px rgba(0,0,0,0.5);
  box-shadow: 0px 15px 25px 1px rgba(0,0,0,0.5);
}

.navbar-default .country-dropdown .country-menu { background: #1f2c52; }

.country-dropdown .icon-country-flag {
  border: 1px solid #fff;
  margin-right: 5px;
}

.navbar .country-menu > li > a {
  color: #fff;
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-weight: 400;
  padding: 8px 12px;
}
.navbar .country-menu > li > a:hover,
.navbar .country-menu > li > a:focus {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
}

.navbar-nav .btn {
  border-width: 2px;
  font-size: 16px;
  padding: 8px 15px;
}

/*-----------------------------------------------------------------------------------*/
/*	Text helper
/*-----------------------------------------------------------------------------------*/
.text-primary { color: #ff3e97; }
.text-success { color: #00be9c; }
.text-gray { color: #757575; }
.text-italic { font-style: italic; }

/*-----------------------------------------------------------------------------------*/
/*	Buttons
/*-----------------------------------------------------------------------------------*/
.btn-primary {
  background: #ff3e97;
  border-color: #ed3a8c;
}
.btn-primary:hover,
.btn-primary:focus {
  background: #ed3a8c;
  border-color: #ff3e97;
}

.btn-outline-primary {
    background-color: #fff;
    border-color: #ff3e98;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus {
background: #fff;
border-color: #ff3e98;
}
a.btn-outline-primary { color: #ff3e98 !important; }

.btn-success {
  background: #00be9c;
  border-color: #00b796;
}
.btn-success:hover,
.btn-success:focus {
  background: #019b7f;
  border-color: #019b7f;
}

.btn-success2 {
  background: #049076;
  border-color: #00b796;
  color: #fff;
}
.btn-success2:hover,
.btn-success2:focus {
  background: #047a64;
  border-color: #03856d;
  color: #fff;
}

.btn-default { background: #ffffff; }

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default.focus:active,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  background: #fff;
}

.btn-transparent {
  background: none;
  border-color: #bec5d5;
}
.btn-transparent:hover,
.btn-transparent:focus {
  background: none;
  border-color: #ff3e97;
}

.btn-save {
  background: #fbf8f9;
  color: #ff4b9d;
}
.btn-save:hover,
.btn-save:focus {
  background: #fff;
  border-color: #bfbfbf;
  border-top-color: transparent;
  color: #ff4b9d;
}

.btn-border {
  background: none;
  border-color: #ff3e97;
  border-width: 2px;
  color: #ff3e97;
}
.btn-border:hover,
.btn-border:focus {
  background: #fff;
  border-color: #ff87c7;
  color: #ff3e97;
}

.btn-cancel {
  background: none;
  border: none;
  color: #ff3e97;
}
.btn-cancel:hover,
.btn-cancel:focus {
  background: none;
  border: none;
  color: #ff3e97;
  text-decoration: underline;
}

.btn-blue {
  background: #1f2c52;
  border-color: #192446;
  color: #fff;
}
.btn-blue:hover,
.btn-blue:focus {
  background: #192446;
  border-color: #1f2c52;
  color: #fff;
}

.btn-bordered {
  background: #fff;
  border-color: #ff3e97;
  color: #ff3e97;
}
.btn-bordered:hover,
.btn-bordered:focus {
  background: #fff;
  border-color: #ff3e97;
  color: #ff3e97;
}

/*-----------------------------------------------------------------------------------*/
/*	Breadcrumb
/*-----------------------------------------------------------------------------------*/
.breadcrumb {
  background: none;
  border-radius: 0;
  font-size: 13px;
  padding: 0;
}

.breadcrumb > li + li::before {
  content: "» ";
}

/*-----------------------------------------------------------------------------------*/
/*	Pagination
/*-----------------------------------------------------------------------------------*/
.pagination > li > a, .pagination > li > span {
  background: #ffffff;
}

.pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
  background: #f7f7f7;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  background: #1f2c52;
  border-color: #ddd;
  color: #fff;
  font-weight: 500;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  background: #ecf0f3;
}

/*-----------------------------------------------------------------------------------*/
/*	Input
/*-----------------------------------------------------------------------------------*/
.btn, button, input, label,
.dropdown-menu {
  -webkit-box-shadow: none;
-moz-box-shadow: none;
	box-shadow: none;
  -webkit-appearance: none;
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-weight: 300;
}

.dropdown-menu {
  font-size: 16px;
  font-weight: 300;
}

.checkbox label, .radio label {
  font-weight: 300;
  padding-left: 10px;
}

.input-group-addon {
  background: #fff;
  color: #999;
}

.input-group-addon .glyphicon{ color: #ccd6e2; }

.modal .input-group { margin: 10px 0; }


/*-----------------------------------------------------------------------------------*/
/*	Tab pill
/*-----------------------------------------------------------------------------------*/
.pill-item:hover,
.pill-item:focus { background: #f9f9f9; }

.pill-item > a {
  color: #1f659b;
  font-size: 14px;
  font-weight: 300;
  padding: 6px 12px;
}

.pill-item > a:hover,
.pill-item > a:focus {
  color: #ff3e97;
  text-decoration: none;
}

.pill-item.active { background: #1e2c52; }
.pill-item.active:hover,
.pill-item.active:focus { background: #222833; }

.pill-item.active > a,
.pill-item.active > a:hover,
.pill-item.active > a:focus { color: #fff; }


/*
 * Table
 */
.table > thead > tr > th {
  border-width: 1px;
  font-size: 15px;
}
.table > tbody.no-border > tr > td { border: none; }
.table > tfoot > tr > td {
  border-width: 3px;
  font-size: 16px;
  font-weight: bold;
}

/*-----------------------------------------------------------------------------------*/
/*	Modal
/*-----------------------------------------------------------------------------------*/
.modal { margin-top: 25px; }
.modal .modal-close {
  color: #e5e9f2;
  font-size: 30px;
  position: absolute;
  right: -7px;
  top: -13px;
  z-index: 10;
}
.modal .modal-dialog { width: 500px; }
.modal .modal-dialog.modal-lg { width: 900px; }
.modal .modal-body {
    max-height: calc(100vh - 212px);
    overflow-y: auto;
}
.modal .site-brand {
  background: url('../../../img/frontend/fastjobs-logo.png') no-repeat left top;
  background-size: cover;
  display: inline-block;
  height: 42px;
  overflow: hidden;
  text-indent: -900px;
  width: 155px;
}
.modal .site-brand-blue {
  background: url('../../../img/frontend/logo-fastjobs.png') no-repeat left top;
  background-size: cover;
  display: inline-block;
  height: 49px;
  overflow: hidden;
  text-indent: -900px;
  width: 155px;
}
.modal .modal-header {
  background: #1e2c52;
  color: #fff;
  padding: 15px 15px 0;
}
.modal .modal-header-white {
  background: #ffffff;
  color: #fff;
  padding: 25px 15px 0;
  border-radius: 5px;
}
.modal-content-forgot-pass-prompt {
  border-radius: 5px !important;
}
.modal-forgot-pass-prompt{
  margin-top: 120px !important;
}
.btn-forgot-pass-prompt {
  min-width: 160px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
}
.btn-forgot-pass-prompt-outline {
  color: #FA4094;
  border: 2px solid rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

.btn-forgot-pass-prompt-outline:hover{
  color: #FA4094;
  background-color: rgba(0, 0, 0, 0.08);
}

.modal .modal-dialog-forgot-pass-prompt { width: 420px !important; }

.modal .modal-header > h4 { height: 40px; }

.modal-session-prompt-mb-header-color {
  color: #FA2F2F;
}

.modal h4 {
  line-height: 26px;
  margin: 0 0 5px;
}
.modal p {
  line-height: 20px;
  margin-bottom: 20px;
}
.modal .btn {
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
  text-transform: uppercase;
  border-radius: 5px;
}

.modal-loader {
  color: #fff;
  font-weight: 500;
  margin: auto;
  top: 30%;
}
.modal-loader .modal-dialog { width: 250px !important;}
.modal-loader .modal-body { padding: 0; }
.modal-loader .modal-content {
  background: none;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
-moz-box-shadow: none;
	box-shadow: none;
}

.modal-transparent .modal-content {
  background: rgb(31, 31, 31);
  background: rgba(0, 0, 0, .65);
  color: #fff;
}

.modal-transparent h3 {
  font-size: 19px;
  font-weight: 300;
  line-height: 26px;
  margin-bottom: 80px;
}

.modal .apply-modal {
  height: 42px;
  overflow: hidden;
  text-indent: -900px;
  width: 45.01px;
  left: 239px;
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
}    
/*-----------------------------------------------------------------------------------*/
/*	iziToast
/*-----------------------------------------------------------------------------------*/
.iziToast > .iziToast-body { font-weight: 400; }

.iziToast.new-interview-toast,
.iziToast.new-msg-toast { width: 360px; }
.iziToast.new-interview-toast > .iziToast-body .iziToast-texts,
.iziToast.new-interview-toast > .iziToast-body .iziToast-buttons,
.iziToast.new-msg-toast > .iziToast-body .iziToast-texts,
.iziToast.new-msg-toast > .iziToast-body .iziToast-buttons { float: none; clear: both; }

/*-----------------------------------------------------------------------------------*/
/*	Error/Simple page
/*-----------------------------------------------------------------------------------*/
.error-page,
.simple-page { 
    color: #333333;
    min-height: 500px;
}

.error-page.not-found { background: url(../../../img/fj-404.png) no-repeat bottom right; }
.error-page.access-denied { background: url(../../../img/fj-access-denied.png) no-repeat bottom right; }

.error-page .error-content { padding: 0 20px; }

.error-page h1 { 
  font-size: 80px; 
  font-family: 'Poppins', system-ui, Arial, sans-serif;
}
.simple-page h1 { font-size: 50px; margin-bottom: 10px; }

.error-page h2,
.simple-page h2 {
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 20px;
    font-family: 'Poppins', system-ui, Arial, sans-serif;
}
.simple-page h2 { margin-top: 10px; }
.error-page h6,
.simple-page h6 {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 20px;
    font-family: 'Poppins', system-ui, Arial, sans-serif;
}
.simple-page h6 { margin: 5px 0; font-weight: 400; }

.error-page .block-grid-item,
.simple-page .block-grid-item {
    padding-bottom: 5px;
    padding-top: 5px;
}

.error-page .link-text,
.simple-page .link-text { font-weight: 300; }

/*	Responsive - Tablet
/*-----------------------------------------------------------------------------------*/
@media (max-width: 991px) and (min-width: 768px) {
    .modal .modal-dialog.modal-lg { width: 700px; }
    
    .error-page.not-found { background-size: 420px 245px; }
    .error-page.access-denied { background-size: 360px 334px; background-position: right center; }
    .error-page h1 { font-size: 50px; }
    .error-page h2 { font-size: 20px; }
    .error-page h6 { font-size: 16px; }
}

/*-----------------------------------------------------------------------------------*/
/*	Responsive - Mobile
/*-----------------------------------------------------------------------------------*/
@media (max-width: 767px) {
  .navbar .dropdown-toggle { font-size: 15px; }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #bec5d5;
    font-size: 15px;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus { color: #fff; }
  
  .navbar .country-dropdown { margin: 0; }
  .navbar .country-dropdown .btn-country-dropdown {
    font-weight: 300;
    padding-left: 2px; 
  }

  .modal .site-brand {
    height: 28px;
    margin-top: 3px;
    width: 105px;
  }

  .modal {
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .modal-forgot-pass-prompt{
    margin-top: 0 !important;
    padding: 0px !important;
  }
  .modal-content-forgot-pass-prompt {
    border-radius: 0px !important;
  }
  .modal .modal-header-white {
    border-radius: 0px !important;
  }
  .modal .modal-close {
    font-size: 16px;
    left: 15px;
    top: 13px;
  }
  .modal .modal-dialog,
  .modal .modal-dialog.modal-lg {
    margin: 0;
    width: 100%;
    height: 100%;
  }
  .modal .modal-dialog-forgot-pass-prompt { 
    margin: 0;
    padding: 0 !important;
    width: 100% !important;
    height: 100%;
  }
  .modal .modal-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    box-shadow: none;
  }
  .modal .modal-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 50px;
    padding: 10px;
    border: 0;
  }
  .modal .modal-body {
    position: absolute;
    top: 50px;
    bottom: 0;
    max-height: none !important;
    width: 100%;
    font-weight: 300;
    overflow: auto;
  }

  .modal-loader .modal-dialog { width: auto !important; }
  .modal-loader .modal-content {
    background: rgb(31, 31, 31);
    background: rgba(0, 0, 0, .65);
  }
  .modal-loader .modal-body { top: 25%; }

  .modal-mobile-cancel {
    bottom: 10px;
    color: #fff;
    display: block;
    font-size: 16px;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .modal-mobile-cancel:hover { color: #fff; }

  .modal .btn { padding: 8px 12px; }
  
  .error-page .error-content { padding: 0 10px; }
  .error-page.not-found,
  .error-page.access-denied { background: none; }
  .error-page h1,
  .simple-page h1 { font-size: 28px;  margin: 15px 0; }
  .error-page h2,
  .simple-page h2 { font-size: 18px !important; line-height: 26px; }
  .error-page h6 { font-size: 16px; line-height: 24px; }
}

@media (max-width: 350px) {
  /*.modal-loader .modal-dialog { width: 300px !important; }*/
  .modal-transparent h3 {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .modal-transparent .option-message,
  .modal-transparent .option-message p {
    font-size: 16px;
    line-height: 16px;
  }
}

.optanon-alert-box-wrapper .banner-content .banner-policy-link {
    /*color: #FF4081;*/
    color: #fff;
}

.sidebar .job-alert-icon {
  background-size: contain;
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  background-image: url(../../../../img/frontend/bell-alert.svg);
}
